<div class="back-navigation" coreActivitySection="QRView">
  <button class="back" coreUserActivityClickTracking="Back">◁ Back to category</button>
</div>

<div class="box">
  <ng-container>
    <div class="text">
      <div class="title">{{ title() }}</div>
      <div class="description">
        @if (description(); as description) {
          {{ description }}
        } @else {
          Scan & Play
        }
      </div>
    </div>
    <img class="qrImg" [src]="qrSrc()" alt="">
    <img class="image" [src]="imgSrc()" alt="">
  </ng-container>
</div>
