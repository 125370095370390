import { Component, DestroyRef, HostBinding } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { IRestaurantTable, RestaurantTableService, WidgetUiConfigRef } from '@core';
import { RootComponent } from '@core/lib/root.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    RootComponent,
  ]
})
export class AppComponent {

  @HostBinding('style.--theme-primary-color')
  private themePrimaryColor = this.widgetUiConfigService.primaryColor;
  @HostBinding('style.--theme-primary-light-color')
  private themePrimaryLightColor = this.widgetUiConfigService.primaryColorLight;
  @HostBinding('style.--theme-primary-dark-color')
  private themePrimaryDarkColor = this.widgetUiConfigService.primaryColorDark;
  @HostBinding('style.--theme-secondary-color')
  private themeSecondaryColor = this.widgetUiConfigService.secondaryColor;
  @HostBinding('style.--theme-secondary-light-color')
  private themeSecondaryLightColor = this.widgetUiConfigService.secondaryColorLight;
  @HostBinding('style.--theme-secondary-dark-color')
  private themeSecondaryDarkColor = this.widgetUiConfigService.secondaryColorDark;
  @HostBinding('style.--theme-tertiary-color')
  private themeTertiaryColor = this.widgetUiConfigService.tertiaryColor;
  @HostBinding('style.--theme-tertiary-light-color')
  private themeTertiaryLightColor = this.widgetUiConfigService.tertiaryColorLight;
  @HostBinding('style.--theme-tertiary-dark-color')
  private themeTertiaryDarkColor = this.widgetUiConfigService.tertiaryColorDark;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly widgetUiConfigService: WidgetUiConfigRef,
  ) {
    this.restaurantTableService.table$.pipe(
      filter((table): table is IRestaurantTable => table !== null),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      // Set theme colors
      this.themePrimaryColor = this.widgetUiConfigService.primaryColor;
      this.themePrimaryLightColor = this.widgetUiConfigService.primaryColorLight;
      this.themePrimaryDarkColor = this.widgetUiConfigService.primaryColorDark;
      this.themeSecondaryColor = this.widgetUiConfigService.secondaryColor;
      this.themeSecondaryLightColor = this.widgetUiConfigService.secondaryColorLight;
      this.themeSecondaryDarkColor = this.widgetUiConfigService.secondaryColorDark;
      this.themeTertiaryColor = this.widgetUiConfigService.tertiaryColor;
      this.themeTertiaryLightColor = this.widgetUiConfigService.tertiaryColorLight;
      this.themeTertiaryDarkColor = this.widgetUiConfigService.tertiaryColorDark;
    });
  }
}
