<div class="content" coreActivitySection="CategoryGrid">
  <div class="element"
       (click)="showQRCode(
       'Experience the keg at home',
       'The legendary taste of The Keg, at home. From seasonings and basting sauces to our much-loved dressings and burgers, bring quality Keg products to your kitchen.',
       '/images/products.png',
       '/images/prodQr.png')"
       coreUserActivityClickTracking="ShowProductsQR"
  >
    <img src="/images/products.png" alt="">
    <span>Retail products</span>
  </div>

  <div class="element"
       (click)="showQRCode(
       'Gift cards',
       'Give your colleagues, friends, or family the gift of good food, good company, and good times with a Keg gift card.',
       '/images/card.jpg',
       '/images/cardQr.png')"
       coreUserActivityClickTracking="ShowGiftCardQR"
  >
    <img class="card" src="/images/card.jpg" alt="">
    <span>Gift <br> cards</span>
  </div>

  @if (isQRVisible) {
    <app-qr-view
      (click)="isQRVisible = !isQRVisible"
      [title]="title"
      [imgSrc]="imgSrc"
      [qrSrc]="qrSrc"
      [description]="description"
    ></app-qr-view>
  }

</div>
