@let isMenuPage = pageIs().menu;
@let isInvoicePage = pageIs().invoice;
@let isCharityPage = pageIs().charity;
@let isStorePage = pageIs().store;

<div
  class="tab-bar tab-bar__with-bg"
  [class.hide]="hideTabBar()"
>
  <div class="tabs" coreActivitySection="TabBar">
    <button
      class="tab"
      [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
    >
      <div class="icon">
        <img [class.active]="isMenuPage" src="/images/menu.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isMenuPage">
        <span>{{ 'tabBar.menu' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isInvoicePage ? '/main/home' : '/main/invoice'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
    >
      <div class="icon">
        <img [class.active]="isInvoicePage" src="/images/invoice.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isInvoicePage">
        <span>{{ 'tabBar.invoice' | translate }}</span>
      </div>
    </button>

    <button
      class="tab logo"
      routerLink='/main/home'
      coreUserActivityClickTracking="PageClose"
    >
      <img class="logoImg" src="/images/logo.png" alt=""/>
    </button>

    <button
      class="tab"
      [routerLink]="isCharityPage ? '/main/home' : '/main/charity'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isCharityPage ? 'CharityPageClose' : 'CharityPageOpen'"
    >
      <div class="icon">
        <img [class.active]="isCharityPage" src="/images/charity.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isCharityPage">
        <span>{{ 'tabBar.charity' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isStorePage ? '/main/home' : '/main/store'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isStorePage ? 'StorePageClose' : 'StorePageOpen'"
    >
      <div class="icon">
        <img [class.active]="isStorePage" src="/images/store.svg" alt=""  />
      </div>
      <div class="label" [class.active]="isStorePage">
        <span>{{ 'tabBar.store' | translate }}</span>
      </div>
    </button>
  </div>
</div>

@if (hideTabBar()) {
  <core-interacts-present/>
}
