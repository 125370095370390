<div class="content">
  <div class="content__image">
    <img src="/images/kitchen.jpg" alt="">
    <div class="content__image__text">
      <h1>Thank you for being with us</h1>
      <p>We hope you enjoyed our cuisine, as we tried our best. Please scan the QR code to pay your bill.</p>
    </div>
  </div>

  <div class="content__qr">
    <div class="description">{{ tableName }}</div>

    @if (qrUrl) {
      <img class="qr_img" [src]="qrUrl" alt="">
    }
  </div>
</div>
